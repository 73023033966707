import React, { useRef, useState } from "react"
import { size } from "lodash"
import moment from "moment"
import { NumericFormat } from "react-number-format"
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row"
import { Button } from "primereact/button"
import { useLibros } from "../../hooks"
import { FormLibro } from "../../components/libro"
import { new_columns_libros } from "../../utils/dictionary"

export function TableLibroContribuyente({ Libro, updateLibroTable }) {

    const [visible, setVisible] = useState(false);
    const [contentDialog, setContentDialog] = useState(false);

    const onCampos = {
        Fecha: true,
        NoCorrelativo: true,
        Registro: true,
        NombreCliente: true,
        VentasGravadas: true,
        Debito: true,
        Retencion: true,
        Exentas: true,
        Importacion: true,
        Subtotal: true,
        Percepcion: true,
        NoSujetas: true,
        TotalVenta: true,
    }

    const { deleteLibroDetalle, updateLibroDetalle_add, getResolucionCliente } = useLibros();

    const dt = useRef(null);
    const toast = useRef(null);

    const Ventas_GravadasTotal = () => {
        let total = 0

        if (size(Libro) > 0) {

            for (const value of Libro) {
                if (value.Ventas_Gravadas != null) {

                    total += parseFloat(value.Ventas_Gravadas)
                }
            }
        }
        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }
    const Debito_FiscalTotal = () => {
        let total = 0

        if (size(Libro) > 0) {

            for (const value of Libro) {
                if (value.Debito_Fiscal != null) {

                    total += parseFloat(value.Debito_Fiscal)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }
    const Venta_TotalTotal = () => {
        let total = 0

        if (size(Libro) > 0) {

            for (const value of Libro) {
                if (value.Venta_Total != null) {

                    total += parseFloat(value.Venta_Total)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const exportExcel = async () => {

        let config_format_ap = []

        for (const libro of Libro) {

            let ap_data = {
                Correlativo: libro.Correlativo,
                Fecha: libro.Fecha,
                Numero_Correlativo_por_Tipo_de_Documento: libro.Numero_Correlativo_por_Tipo_de_Documento,
                Numero_de_Registro: libro.Numero_de_Registro,
                Nombre_del_Cliente: libro.Nombre_del_Cliente,
                Ventas_Gravadas: libro.Ventas_Gravadas,
                Debito_Fiscal: libro.Debito_Fiscal,
                Venta_Total: libro.Venta_Total,
            }

            config_format_ap.push(ap_data)

        }

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(config_format_ap);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'LibroCompras');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const exportCSV = async (selectionOnly) => {

        const response_resolucion = await getResolucionCliente(localStorage.getItem("Cliente"));

        let csvContent = "data:text/csv;charset=utf-8,";

        for await (const data of Libro) {
            if (data.Numero_de_Registro != "-") {

                let null_Ventas_Gravadas = (data.Ventas_Gravadas == null) ? "0.00" : data.Ventas_Gravadas;
                let valor_Ventas_Gravadas = (null_Ventas_Gravadas < 0) ? (null_Ventas_Gravadas * (-1)) : null_Ventas_Gravadas;
                let null_Debito_Fiscal = (data.Debito_Fiscal == null) ? "0.00" : data.Debito_Fiscal;
                let valor_Debito_Fiscal = (null_Debito_Fiscal < 0) ? (null_Debito_Fiscal * (-1)) : null_Debito_Fiscal;
                let null_Venta_Total = (data.Venta_Total == null) ? "0.00" : data.Venta_Total;
                let valor_Venta_Total = (null_Venta_Total < 0) ? (null_Venta_Total * (-1)) : null_Venta_Total;

                let tipoDocumento = "03";
                let resolucionValor = response_resolucion.Resolucion;
                let serieValor = response_resolucion.Serie;

                if (null_Ventas_Gravadas < 0 || null_Debito_Fiscal < 0 || null_Venta_Total < 0) {
                    tipoDocumento = "05"
                    resolucionValor = response_resolucion.ResolucionNotaCredito;
                    serieValor = response_resolucion.SerieNotaCredito;
                }

                csvContent += `${moment(data.Fecha).format("DD/MM/YYYY")};1;03;${resolucionValor};${serieValor};${data.Numero_Correlativo_por_Tipo_de_Documento};${data.Numero_Correlativo_por_Tipo_de_Documento};${data.Numero_de_Registro};${data.Nombre_del_Cliente};0.00;0.00;${valor_Ventas_Gravadas};${valor_Debito_Fiscal};0.00;0.00;${valor_Venta_Total};;1\n`;

            }
        }

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `Contribuyentes-${response_resolucion.NombreCliente}.csv`);
        document.body.appendChild(link); // Required for Firefox
        link.click();

    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
        </div>
    );

    const templateFecha = (rowData) => {
        const date_str = moment(rowData.Fecha).format("YYYY-MM-DD")
        return (date_str == "Invalid date") ? rowData.Fecha : date_str
    }

    const templateFixTotal = (rowData) => {
        return parseFloat(rowData.Compras_totales).toFixed(2)
    }
    const templateFixGravadas = (rowData) => {
        return (rowData.Internas) ? parseFloat(rowData.Internas).toFixed(2) : ""
    }
    const templateFixIva = (rowData) => {
        return parseFloat(rowData.IVA).toFixed(2)
    }
    const templateFixRetencion = (rowData) => {

        return (rowData.Retencion != null) ? parseFloat(rowData.Retencion).toFixed(2) : 0
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">Editar Detalle de Libro</span>
        </div>
    );

    const footerContent = (
        <div>
            
        </div>
    );

    const deleteDetalleLibro = async (rowValue, e) => {

        confirmPopup({
            target: e,
            message: '¿Deseas eliminar esta fila?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: async () => {
                toast.current.show({ severity: 'info', summary: 'Confirmado', detail: 'El registro se ha eliminado Correctamente', life: 3000 });
                await deleteLibroDetalle(rowValue.Libro_id, rowValue.FilaGroup)

                await updateLibroTable(rowValue.Libro_id);
            },
            reject: () => {
                toast.current.show({ severity: 'warn', summary: 'Rechazado', detail: 'No se Elimino el registro', life: 3000 });
            }
        }); 

    }

    const onUpdateLibroTable = async (id_libro) => {

        if (id_libro != undefined) {

            await updateLibroTable(id_libro)

        }
    }

    const updateFormData = async (formValue, Libro, Fila) => {

        for await (const data of Object.keys(formValue)) {
            const value_form = formValue[data]

            if (value_form != "" && data in onCampos) {

                if (data in new_columns_libros) {

                    let data_save = {
                        /* FilaGroup: Fila,
                        Libro: Libro,
                        Columna: new_columns_libros[data], */
                        Valor: value_form
                    }

                    await updateLibroDetalle_add(data_save, Libro, Fila, new_columns_libros[data])

                }

            }

        }

        onUpdateLibroTable(Libro)

        setVisible(false)
    }

    const updateDetalleLibro = (rowData) => {

        const onCamposValue = {
            Fecha: rowData.Fecha,
            NoCorrelativo: rowData.Numero_Correlativo_por_Tipo_de_Documento,
            Registro: rowData.Numero_de_Registro,
            NombreCliente: rowData.Nombre_del_Cliente,
            VentasGravadas: rowData.Ventas_Gravadas,
            Debito: rowData.Debito_Fiscal,
            Retencion: "",
            Exentas: "",
            Importacion: "",
            Subtotal: "",
            Percepcion: "",
            NoSujetas: "",
            TotalVenta: rowData.Venta_Total,
        }

        setContentDialog(<FormLibro campos={onCampos} responseUpdate={updateFormData} updateLibro={onUpdateLibroTable} tipoLibro={2} dataValue={onCamposValue} EditLibroId={rowData.Libro_id} EditLibroFila={rowData.FilaGroup} />)
        setVisible(true)
    }

    const actionTemplate = (rowdata) => {

        return (
            <div className="flex flex-wrap justify-content-center gap-3 mb-4">
                <Button severity="danger" onClick={(e) => deleteDetalleLibro(rowdata, e.target)} rounded icon="pi pi-times"></Button>
                <Button severity="warning" onClick={(e) => updateDetalleLibro(rowdata)} rounded icon="pi pi-pencil"></Button>
            </div>
        )
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column header="Compras Exentas" headerStyle={{ textAlign: "center" }} colSpan={2}></Column>
                <Column header="Compras Gravadas" colSpan={2}></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
            </Row>
            <Row>
                <Column header="Correlativo" field="Correlativo" sortable rowSpan={2} ></Column>
                <Column header="Fecha" field="Fecha" sortable rowSpan={2} ></Column>
                <Column header="Numero de Comprobante" field="Numero_de_Comprobante" rowSpan={2} ></Column>
                <Column header="Numero de Registro" field="Numero_de_Registro" rowSpan={2} ></Column>
                <Column header="Nombre del Proveedor" field="Nombre_del_Proveedor" rowSpan={2} ></Column>
                <Column header="Internas" field="ExentasInternas"></Column>
                <Column header="Importacion" field="ExentasImportacion"></Column>
                <Column header="Internas" field="Internas"></Column>
                <Column header="Importacion" field="Importaciones"></Column>
                <Column header="IVA" field="IVA" rowSpan={2} ></Column>
                <Column header="Retencion" field="Retencion" rowSpan={2} ></Column>
                <Column header="Compras Totales" field="Compras_totales" rowSpan={2} ></Column>
                <Column rowSpan={2} colSpan={2} ></Column>
            </Row>
        </ColumnGroup>
    )

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
                <Column footer={Ventas_GravadasTotal} />
                <Column footer={Debito_FiscalTotal} />
                <Column footer={Venta_TotalTotal} colSpan={2} />
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            <Toast ref={toast} />
            <ConfirmPopup />
            <Dialog visible={visible} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                {contentDialog}
            </Dialog>
            <DataTable value={Libro}
                ref={dt}
                footerColumnGroup={footerGroup}
                stripedRows
                size="small"
                sortField="Correlativo"
                sortOrder={-1}
                header={header}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                editMode="row" dataKey="id">
                <Column header="Correlativo" field="Correlativo" sortable ></Column>
                <Column header="Fecha de Emision" sortable field="Fecha"></Column>
                <Column header="No. Correlativo por Tipo de Documento" field="Numero_Correlativo_por_Tipo_de_Documento"></Column>
                <Column header="No. de Registro" field="Numero_de_Registro"></Column>
                <Column header="Nombre del Cliente" field="Nombre_del_Cliente"></Column>
                <Column header="Ventas Gravadas" field="Ventas_Gravadas"></Column>
                <Column header="Debito Fiscal" field="Debito_Fiscal"></Column>
                <Column header="Ventas Totales" field="Venta_Total"></Column>
                <Column body={actionTemplate}></Column>
            </DataTable>
        </>
    )
}