import React, { useEffect, useRef, useState } from "react"
import { useFormik } from "formik";
import { evaluate } from "mathjs";
import { HotKeys } from "react-hotkeys"
import * as Yup from "yup";
import { size } from "lodash"
import moment from "moment";
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { useLibros } from "../../hooks"
import { meses } from "../../utils/dictionary"

const keyMap = {
    SUBMIT_FORM: "ctrl+enter",
    FOCUS_FIELD: "ctrl+a",
    // Define más atajos aquí
};

export function FormLibro({ campos, response, updateLibro, tipoLibro, dataValue, responseUpdate, EditLibroId, EditLibroFila }) {

    const [TipoProveedorLibro, setTipoProveedorLibro] = useState(0);

    const Fecha_ref = useRef();
    const Comprobante_ref = useRef();
    const DelNo_ref = useRef();
    const AlNo_ref = useRef();
    const NoCorrelativo_ref = useRef();
    const Registro_ref = useRef();
    const Proveedor_ref = useRef();
    const NombreCliente_ref = useRef();
    const Gravadas_ref = useRef();
    const VentasGravadas_ref = useRef();
    const Iva_ref = useRef();
    const Debito_ref = useRef();
    const Fovial_ref = useRef();
    const Retencion_ref = useRef();
    const Exentas_ref = useRef();
    const Importacion_ref = useRef();
    const Subtotal_ref = useRef();
    const Percepcion_ref = useRef();
    const NoSujetas_ref = useRef();
    const Total_ref = useRef();
    const Locales_ref = useRef();
    const TotalVenta_ref = useRef();

    const { getProveedorByNrc, getLibroDetalleFormatFilter } = useLibros();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: (formData) => {
            if (size(dataValue) == 0){

                response(formData)
            }else{
                responseUpdate(formData, EditLibroId, EditLibroFila)
            }
            onCleanForm();
        }
    })

    console.log(formik.errors)

    useEffect(() => {
        formik.setFieldValue("Mes", moment().month());
        formik.setFieldValue("Year", moment().year());

        if (size(dataValue) > 0){

            for (const [key, value] of Object.entries(dataValue)){
                formik.setFieldValue(key, value)
            }

        }

    }, [])

    useEffect(() => {
        (async () => {
            const fecha_libro = moment([0, 0, 1]).month(formik.values.Mes - 1).year(formik.values.Year).format("YYYY-MM-DD")

            const id_libro = await getLibroDetalleFormatFilter(localStorage.getItem("Cliente"), fecha_libro, tipoLibro)

            if (size(dataValue) > 0){
                formik.setFieldValue("IdLibro", EditLibroId);
            }else{

                formik.setFieldValue("IdLibro", id_libro);
            }


            if (size(dataValue) == 0){

                updateLibro(id_libro)

            }
        })()
    }, [formik.values.Mes, formik.values.Year])

    const handlers = {
        SUBMIT_FORM: () => alert("Formulario enviado"),
        FOCUS_FIELD: () => Fecha_ref.current.focus(),
        // Maneja más atajos aquí
    };

    const onCleanForm = () => {

        formik.setFieldValue("Comprobante", "");
        formik.setFieldValue("DelNo", "");
        formik.setFieldValue("AlNo", "");
        formik.setFieldValue("NoCorrelativo", "");
        formik.setFieldValue("Registro", "");
        formik.setFieldValue("Proveedor", "");
        formik.setFieldValue("NombreCliente", "");
        formik.setFieldValue("Gravadas", "");
        formik.setFieldValue("VentasGravadas", "");
        formik.setFieldValue("Iva", "");
        formik.setFieldValue("Debito", "");
        formik.setFieldValue("Fovial", "");
        formik.setFieldValue("Retencion", "");
        formik.setFieldValue("Exentas", "");
        formik.setFieldValue("Importacion", "");
        formik.setFieldValue("Subtotal", "");
        formik.setFieldValue("Percepcion", "");
        formik.setFieldValue("NoSujetas", "");
        formik.setFieldValue("Total", "");
        formik.setFieldValue("TotalVenta", "");
        formik.setFieldValue("Locales", "");

        Fecha_ref.current.focus();
    }

    const onCalcField = () => {

        let valor_gravadas = "0";
        switch (tipoLibro) {
            case 1:
                valor_gravadas = formik.values.Gravadas != "0" ? formik.values.Gravadas : 0;
                break;
        
            case 2:
                valor_gravadas = formik.values.VentasGravadas != "0" ? formik.values.VentasGravadas : 0;
                break;

            case 3:
                valor_gravadas = formik.values.Locales != "0" ? formik.values.Locales : 0;
                break;
        }

        let iva_calc = valor_gravadas * 0.13;
        let retencion_calc = 0;

        if (TipoProveedorLibro == 2 && valor_gravadas > 99.99 && tipoLibro == 1) {
            retencion_calc = valor_gravadas * 0.01;
        } else {
            retencion_calc = 0
        }

        let total_calc = parseFloat(valor_gravadas) + parseFloat(iva_calc) + parseFloat(retencion_calc);
        let subtotal_calc = parseFloat(valor_gravadas) + parseFloat(iva_calc)

        switch (tipoLibro) {
            case 1:
                
                formik.setFieldValue("Iva", iva_calc.toFixed(2));
                formik.setFieldValue("Total", total_calc.toFixed(2));
                formik.setFieldValue("Subtotal", subtotal_calc.toFixed(2));
                formik.setFieldValue("Retencion", retencion_calc.toFixed(2));
                break;
        
            case 2:
                formik.setFieldValue("Debito", iva_calc.toFixed(2));
                formik.setFieldValue("TotalVenta", total_calc.toFixed(2));
                formik.setFieldValue("Subtotal", subtotal_calc.toFixed(2));
                break;

            case 3:

                let locales_str = (formik.values.Locales != "") ? String(formik.values.Locales) : "0";
                
                formik.setFieldValue("TotalVenta", evaluate(locales_str));
                formik.setFieldValue("Subtotal", evaluate(locales_str));
                formik.setFieldValue("Locales", evaluate(locales_str));
                break;
        }


    }

    const onSearchProveedor = async (data) => {
        const response_proveedor = await getProveedorByNrc(data)

        if (size(response_proveedor) > 0) {
            switch (tipoLibro) {
                case 1:
                    formik.setFieldValue("Proveedor", response_proveedor[0].NombreProveedor)
                    break;
            
                case 2:
                    formik.setFieldValue("NombreCliente", response_proveedor[0].NombreProveedor)
                    break;
            }
            setTipoProveedorLibro(response_proveedor[0].TipoProveedor)
        }

    }

    return (
        <HotKeys keyMap={keyMap} handlers={handlers} >
            <form onSubmit={formik.handleSubmit}>
                {size(dataValue) == 0 && (
                    <>
                        <div className="grid my-3">
                            <div className="col-6">
                                <span className="p-float-label">
                                    <Dropdown id="Mes" name="Mes" className="w-full" value={formik.values.Mes} options={meses} optionLabel="name" onChange={(e) => formik.setFieldValue("Mes", e.value)} />
                                    <label htmlFor="Mes">Mes</label>
                                </span>
                            </div>
                            <div className="col-6">
                                <span className="p-float-label">
                                    <InputText id="Year" name="Year" className="w-full" value={formik.values.Year} onChange={formik.handleChange} />
                                    <label htmlFor="Year">Año</label>
                                </span>
                            </div>
                        </div>
                        <hr />
                    </>
                )}
                <div className="grid my-3">
                    <div className="col-12">

                        <div className="grid">

                            {campos?.Fecha && (

                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Fecha" style={{ fontSize: '1.5rem' }}>Fecha</label>
                                        <InputText id="Fecha" ref={Fecha_ref} name="Fecha" value={formik.values.Fecha} onChange={formik.handleChange} className="w-full p-inputtext-lg" onBlur={(e) => {
                                            let mes_seleccionado = moment().month(formik.values.Mes);

                                            if (moment(e.target.value).month() + 1 != mes_seleccionado.month()) {
                                                Fecha_ref.current.focus();
                                                formik.setFieldValue("Fecha", "");
                                            }

                                        }} onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                e.preventDefault();
                                                switch (tipoLibro) {
                                                    case 1:
                                                        Comprobante_ref.current.focus();
                                                        break;
                                                
                                                    case 2:
                                                        NoCorrelativo_ref.current.focus();
                                                        break;
                                                    case 3:
                                                        DelNo_ref.current.focus();
                                                        break;
                                                }
                                            }
                                        }} />
                                    </div>
                                </div>

                            )}

                            {campos?.Comprobante && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Comprobante" style={{ fontSize: '1.5rem' }}>No. Comprobante</label>
                                        <InputText
                                            id="Comprobante"
                                            ref={Comprobante_ref}
                                            name="Comprobante"
                                            value={formik.values.Comprobante}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Registro_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {campos?.NoCorrelativo && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="NoCorrelativo" style={{ fontSize: '1.5rem' }}>No. NoCorrelativo</label>
                                        <InputText
                                            id="NoCorrelativo"
                                            ref={NoCorrelativo_ref}
                                            name="NoCorrelativo"
                                            value={formik.values.NoCorrelativo}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Registro_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}




                            {campos?.DelNo && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="DelNo" style={{ fontSize: '1.5rem' }}>No. DelNo</label>
                                        <InputText
                                            id="DelNo"
                                            ref={DelNo_ref}
                                            name="DelNo"
                                            value={formik.values.DelNo}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    AlNo_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {campos?.AlNo && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="AlNo" style={{ fontSize: '1.5rem' }}>No. AlNo</label>
                                        <InputText
                                            id="AlNo"
                                            ref={AlNo_ref}
                                            name="AlNo"
                                            value={formik.values.AlNo}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Locales_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Registro && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Registro" style={{ fontSize: '1.5rem' }}>No. Registro</label>
                                        <InputText
                                            id="Registro"
                                            ref={Registro_ref}
                                            name="Registro"
                                            value={formik.values.Registro}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={() => {
                                                onCalcField();
                                                onSearchProveedor(formik.values.Registro);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    switch (tipoLibro) {
                                                        case 1:
                                                            Proveedor_ref.current.focus();
                                                            break;
                                                    
                                                        case 2:
                                                            NombreCliente_ref.current.focus();
                                                            break;
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Proveedor && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Proveedor" style={{ fontSize: '1.5rem' }}>Nombre de Proveedor</label>
                                        <InputText
                                            id="Proveedor"
                                            ref={Proveedor_ref}
                                            name="Proveedor"
                                            value={formik.values.Proveedor}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Gravadas_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {campos?.NombreCliente && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="NombreCliente" style={{ fontSize: '1.5rem' }}>Nombre de NombreCliente</label>
                                        <InputText
                                            id="NombreCliente"
                                            ref={NombreCliente_ref}
                                            name="NombreCliente"
                                            value={formik.values.NombreCliente}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    VentasGravadas_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Gravadas && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Gravadas" style={{ fontSize: '1.5rem' }}>Gravadas Locales</label>
                                        <InputText
                                            id="Gravadas"
                                            ref={Gravadas_ref}
                                            name="Gravadas"
                                            value={formik.values.Gravadas}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Importacion_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {campos?.VentasGravadas && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="VentasGravadas" style={{ fontSize: '1.5rem' }}>VentasGravadas Locales</label>
                                        <InputText
                                            id="VentasGravadas"
                                            ref={VentasGravadas_ref}
                                            name="VentasGravadas"
                                            value={formik.values.VentasGravadas}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Importacion_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {campos?.Locales && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Locales" style={{ fontSize: '1.5rem' }}>Locales</label>
                                        <InputText
                                            id="Locales"
                                            ref={Locales_ref}
                                            name="Locales"
                                            value={formik.values.Locales}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Subtotal_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Importacion && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Importacion" style={{ fontSize: '1.5rem' }}>Gravadas Importaciones</label>
                                        <InputText
                                            id="Importacion"
                                            ref={Importacion_ref}
                                            name="Importacion"
                                            value={formik.values.Importacion}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    switch (tipoLibro) {
                                                        case 1:
                                                            Iva_ref.current.focus();
                                                            break;
                                                    
                                                        case 2:
                                                            Debito_ref.current.focus();
                                                            break;
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Iva && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Iva" style={{ fontSize: '1.5rem' }}>IVA</label>
                                        <InputText
                                            id="Iva"
                                            ref={Iva_ref}
                                            name="Iva"
                                            value={formik.values.Iva}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Subtotal_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {campos?.Debito && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Debito" style={{ fontSize: '1.5rem' }}>Debito</label>
                                        <InputText
                                            id="Debito"
                                            ref={Debito_ref}
                                            name="Debito"
                                            value={formik.values.Debito}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Subtotal_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Subtotal && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Subtotal" style={{ fontSize: '1.5rem' }}>Sub-Total</label>
                                        <InputText
                                            id="Subtotal"
                                            ref={Subtotal_ref}
                                            name="Subtotal"
                                            value={formik.values.Subtotal}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {

                                                    switch (tipoLibro) {
                                                        case 1:
                                                            Percepcion_ref.current.focus();
                                                            break;
                                                    
                                                        case 2:
                                                            Percepcion_ref.current.focus();
                                                            break;
                                                        case 3:
                                                            NoSujetas_ref.current.focus();
                                                            break;
                                                    }

                                                    e.preventDefault();

                                                    
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Percepcion && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Percepcion" style={{ fontSize: '1.5rem' }}>(+) IVA Percibido</label>
                                        <InputText
                                            id="Percepcion"
                                            ref={Percepcion_ref}
                                            name="Percepcion"
                                            value={formik.values.Percepcion}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Retencion_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Retencion && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Retencion" style={{ fontSize: '1.5rem' }}>(-) IVA Retenido</label>
                                        <InputText
                                            id="Retencion"
                                            ref={Retencion_ref}
                                            name="Retencion"
                                            value={formik.values.Retencion}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    NoSujetas_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.NoSujetas && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="NoSujetas" style={{ fontSize: '1.5rem' }}>Ventas No Sujetas</label>
                                        <InputText
                                            id="NoSujetas"
                                            ref={NoSujetas_ref}
                                            name="NoSujetas"
                                            value={formik.values.NoSujetas}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    Exentas_ref.current.focus();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Exentas && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Exentas" style={{ fontSize: '1.5rem' }}>Ventas Exentas</label>
                                        <InputText
                                            id="Exentas"
                                            ref={Exentas_ref}
                                            name="Exentas"
                                            value={formik.values.Exentas}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    switch (tipoLibro) {
                                                        case 1:
                                                            Fovial_ref.current.focus();
                                                            break;
                                                    
                                                        case 2:
                                                            Fovial_ref.current.focus();
                                                            break;
                                                        case 3:
                                                            TotalVenta_ref.current.focus();

                                                            break;
                                                    }
                                                    
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Fovial && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Fovial" style={{ fontSize: '1.5rem' }}>(+) Fovial y Cotrans</label>
                                        <InputText
                                            id="Fovial"
                                            ref={Fovial_ref}
                                            name="Fovial"
                                            value={formik.values.Fovial}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onBlur={onCalcField}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    switch (tipoLibro) {
                                                        case 1:
                                                            Total_ref.current.focus();
                                                            break;
                                                    
                                                        case 2:
                                                            TotalVenta_ref.current.focus();
                                                            break;
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {campos?.Total && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="Total" style={{ fontSize: '1.5rem' }}>Total</label>
                                        <InputText
                                            id="Total"
                                            ref={Total_ref}
                                            name="Total"
                                            value={formik.values.Total}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    //Fecha_ref.current.focus();
                                                    formik.submitForm();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {campos?.TotalVenta && (
                                <div className="col-6" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="field">
                                        <label htmlFor="TotalVenta" style={{ fontSize: '1.5rem' }}>TotalVenta</label>
                                        <InputText
                                            id="TotalVenta"
                                            ref={TotalVenta_ref}
                                            name="TotalVenta"
                                            value={formik.values.TotalVenta}
                                            onChange={formik.handleChange}
                                            className="w-full p-inputtext-lg"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    //Fecha_ref.current.focus();
                                                    formik.submitForm();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                    {/* <div className="col-6">
                    <h6>Detalle de Impuestos</h6>
                </div> */}
                </div>

                <div className="grid">
                    <div className="col-12">
                        <Button severity="primary" type="submit" size="large">Guardar</Button>
                    </div>
                </div>
            </form>
        </HotKeys>
    )
}

function initialValues() {
    return {
        IdLibro: "",
        Mes: "",
        Year: "",
        Fecha: "",
        Comprobante: "",
        DelNo: "",
        AlNo: "",
        NoCorrelativo: "",
        Registro: "",
        Proveedor: "",
        NombreCliente: "",
        Gravadas: "",
        VentasGravadas: "",
        Iva: "",
        Debito: "",
        Fovial: "",
        Retencion: "",
        Exentas: "",
        Importacion: "",
        Subtotal: "",
        Percepcion: "",
        NoSujetas: "",
        Total: "",
        TotalVenta: "",
        Locales: "",
    }
}

function validationSchema() {
    return {
        IdLibro: Yup.string().required(true),
        Mes: Yup.string().required(true),
        Year: Yup.string().required(true),
        Fecha: Yup.string(),
        Comprobante: Yup.string(),
        DelNo: Yup.string(),
        AlNo: Yup.string(),
        NoCorrelativo: Yup.string(),
        Registro: Yup.string(),
        Proveedor: Yup.string(),
        NombreCliente: Yup.string(),
        Gravadas: Yup.string(),
        VentasGravadas: Yup.string(),
        Iva: Yup.string(),
        Debito: Yup.string(),
        Fovial: Yup.string(),
        Retencion: Yup.string(),
        Exentas: Yup.string(),
        Importacion: Yup.string(),
        Subtotal: Yup.string(),
        Percepcion: Yup.string(),
        NoSujetas: Yup.string(),
        Total: Yup.string(),
        TotalVenta: Yup.string(),
        Locales: Yup.string(),
    }
}