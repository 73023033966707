import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightPanel from './AppRightPanel';
import {useAuth} from "./hooks/useAuth"

import Dashboard from './components/Dashboard';
import {LibroComprasPage, LibroConsumidorFinalPage, LibroVentasPage, GenerarPartidasPage} from "./pages/libros"
import {CompraCliente, ContribuyenteCliente, ConsumidorFinalCliente} from "./pages/libroCliente"
import {LoadJsonPage, LoadPartidasPage, LoadLibroPage} from "./pages/importaciones"
import {AnalisisDeclaracionPage, DocumentosLegales} from "./pages/reportes"

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = (props) => {
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(true);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [ripple, setRipple] = useState(false);
    const [rightPanelActive, setRightPanelActive] = useState(false);
    const [colorScheme, setColorScheme] = useState('light');
    const [topbarScheme, setTopbarScheme] = useState('light');
    const [menuScheme, setMenuScheme] = useState('light');
    const [themeScheme, setThemeScheme] = useState('light');
    const [theme, setTheme] = useState('purple');
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const { auth, logout } = useAuth();

    const menu = [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            to: '/'
        },
        {
            label: 'Libros Cliente',
            icon: 'pi pi-home',
            items: [
                {
                    label: 'Compras',
                    icon: 'pi pi-home',
                    to: '/librosOpti/compras'
                },
                {
                    label: 'Venta a Contribuyente',
                    icon: 'pi pi-home',
                    to: '/librosOpti/contribuyentes'
                },
                {
                    label: 'Venta a Consumidor Final',
                    icon: 'pi pi-home',
                    to: '/librosOpti/consumidor_final'
                },
            ]
        },
        {
            label: 'Libros',
            icon: 'pi pi-home',
            items: [
                {
                    label: 'Compras',
                    icon: 'pi pi-home',
                    to: '/libros/compras'
                },
                {
                    label: 'Venta a Contribuyente',
                    icon: 'pi pi-home',
                    to: '/libros/contribuyentes'
                },
                {
                    label: 'Venta a Consumidor Final',
                    icon: 'pi pi-home',
                    to: '/libros/consumidor_final'
                },
            ]
        },
        {
            label: 'Importaciones',
            icon: 'pi pi-home',
            items: [
                {
                    label: 'Libros Excel',
                    icon: 'pi pi-home',
                    to: '/import/ledger'
                },
                {
                    label: 'Configuraciones Partida',
                    icon: 'pi pi-home',
                    to: '/import/partidas'
                },
                {
                    label: 'JSON',
                    icon: 'pi pi-home',
                    to: '/import/json'
                },
                {
                    label: 'Generar Partidas',
                    icon: 'pi pi-home',
                    to: '/libros/partidas'
                },
            ]
        },
        {
            label: 'Reportes',
            icon: 'pi pi-home',
            items: [
                {
                    label: 'Analisis',
                    icon: 'pi pi-home',
                    to: '/report/analisis'
                },
                {
                    label: 'Libros Legales',
                    icon: 'pi pi-home',
                    to: '/report/Legales'
                },
            ]
        },
        {
            label: 'Logout',
            icon: 'pi pi-power-off',
            command: () => logout()
        }
    ];

    let menuClick;
    let rightPanelClick;
    let configClick;
    let searchClick;
    let topbarUserMenuClick;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setResetActiveIndex(true);
            hideOverlayMenu();
        }
        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };

    const onMenuClick = (event) => {
        if (menuActive && event.target.className === 'layout-menu-container') {
            setResetActiveIndex(true);
            setMenuActive(false);
        }
        menuClick = true;
    };

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        if (menuMode === 'sidebar') {
            if (sidebarStatic) {
                setSidebarActive(true);
            }
        } else {
            setSidebarActive(false);
            if (topbarScheme !== menuScheme) {
                setMenuScheme(topbarScheme);
            }
        }
        if (topbarScheme === 'dark') {
            setThemeScheme('dark');
        }
    };

    const onColorSchemeChange = (scheme) => {
        setColorScheme(scheme);
        props.setColorScheme(scheme);
    };

    const onThemeSchemeChange = (scheme) => {
        setThemeScheme(scheme);
        setMenuScheme(scheme);
        setTopbarScheme(scheme);
    };

    const onTopbarSchemeChange = (scheme) => {
        setTopbarScheme(scheme);
    };

    const onMenuSchemeChange = (scheme) => {
        setMenuScheme(scheme);
    };

    const onThemeChange = (themeColor) => {
        setTheme(themeColor);
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setRightPanelActive(false);

        if (isMobile()) {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }
        event.preventDefault();
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const hideOverlayMenu = () => {
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const onRightPanelClick = () => {
        rightPanelClick = true;
    };

    const onRightPanelButtonClick = () => {
        setRightPanelActive((prevState) => !prevState);
        rightPanelClick = true;
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    };

    const onTopbarSearchToggle = () => {
        setSearchActive((prevState) => !prevState);
        searchClick = true;
    };

    const onTopbarSearchClick = () => {
        searchClick = true;
    };

    const onTopbarUserMenuClick = () => {
        setTopbarUserMenuActive((prevState) => !prevState);
        topbarUserMenuClick = true;
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            setSearchActive(false);
            searchClick = false;
        }

        if (!topbarUserMenuClick && topbarUserMenuActive) {
            setTopbarUserMenuActive(false);
            topbarUserMenuClick = false;
        }

        if (!rightPanelClick && rightPanelActive) {
            setRightPanelActive(false);
        }

        if (!configClick && configActive) {
            setConfigActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setResetActiveIndex(true);
                setMenuActive(false);
            }

            if (staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        searchClick = false;
        topbarUserMenuClick = false;
        rightPanelClick = false;
        configClick = false;
        menuClick = false;
    };

    const onSidebarMouseOver = () => {
        setSidebarActive(!isMobile());
    };

    const onSidebarMouseLeave = () => {
        setSidebarActive(false);
    };

    const onToggleMenu = (event) => {
        menuClick = true;
        setSidebarStatic((prevState) => !prevState);

        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const layoutClassName = classNames(
        'layout-wrapper',
        {
            'layout-sidebar': menuMode === 'sidebar',
            'layout-static': menuMode === 'sidebar' && sidebarStatic,
            'layout-horizontal': menuMode === 'horizontal',
            'layout-rightpanel-active': rightPanelActive,
            'layout-slim': menuMode === 'slim',
            'layout-mobile-active': staticMenuMobileActive,
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        },
        'layout-menu-' + menuScheme + ' layout-topbar-' + topbarScheme
    );

    if (!auth) return navigate('/login');

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar
                topbarScheme={topbarScheme}
                onRightPanelButtonClick={onRightPanelButtonClick}
                searchActive={searchActive}
                onTopbarSearchToggle={onTopbarSearchToggle}
                onTopbarSearchClick={onTopbarSearchClick}
                topbarUserMenuActive={topbarUserMenuActive}
                onTopbarUserMenuClick={onTopbarUserMenuClick}
                menu={menu}
                menuActive={menuActive}
                onRootMenuItemClick={onRootMenuItemClick}
                mobileMenuActive={staticMenuMobileActive}
                onMenuItemClick={onMenuItemClick}
                menuMode={menuMode}
                sidebarStatic={sidebarStatic}
                sidebarActive={sidebarActive}
                onSidebarMouseOver={onSidebarMouseOver}
                onSidebarMouseLeave={onSidebarMouseLeave}
                onToggleMenu={onToggleMenu}
                onMenuButtonClick={onMenuButtonClick}
                resetActiveIndex={resetActiveIndex}
                onMenuClick={onMenuClick}
            />

            <AppRightPanel onRightPanelClick={onRightPanelClick} />

            <AppConfig
                configActive={configActive}
                onConfigButtonClick={onConfigButtonClick}
                onConfigClick={onConfigClick}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                ripple={ripple}
                onRippleChange={onRippleChange}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
                colorScheme={colorScheme}
                onColorSchemeChange={onColorSchemeChange}
                topbarScheme={topbarScheme}
                onTopbarSchemeChange={onTopbarSchemeChange}
                menuScheme={menuScheme}
                onMenuSchemeChange={onMenuSchemeChange}
                themeScheme={themeScheme}
                onThemeSchemeChange={onThemeSchemeChange}
                theme={theme}
                onThemeChange={onThemeChange}
            />

            <div className="layout-main">
                <div className="layout-content">
                    <Routes>
                        <Route path="/" exact="true" element={<Dashboard />} />
                        <Route path="/libros/compras" exact="true" element={<LibroComprasPage />} />
                        <Route path="/libros/contribuyentes" exact="true" element={<LibroVentasPage />} />
                        <Route path="/libros/consumidor_final" exact="true" element={<LibroConsumidorFinalPage />} />
                        <Route path="/libros/partidas" exact="true" element={<GenerarPartidasPage />} />

                        <Route path="/librosOpti/compras" exact="true" element={<CompraCliente />} />
                        <Route path="/librosOpti/contribuyentes" exact="true" element={<ContribuyenteCliente />} />
                        <Route path="/librosOpti/consumidor_final" exact="true" element={<ConsumidorFinalCliente />} />

                        <Route path="/import/json" exact="true" element={<LoadJsonPage />} />
                        <Route path="/import/partidas" exact="true" element={<LoadPartidasPage />} />
                        <Route path="/import/ledger" exact="true" element={<LoadLibroPage />} />

                        <Route path="/report/analisis" exact="true" element={<AnalisisDeclaracionPage />} />
                        <Route path="/report/Legales" exact="true" element={<DocumentosLegales />} />
                    </Routes>
                </div>

                <AppFooter />
            </div>

            <div className="layout-mask modal-in"></div>
        </div>
    );
};

export default App;
