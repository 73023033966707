export const Libros = {
    1: "Compras",
    2: "Ventas a Contribuyente",
    3: "Ventas Consumidor Final",
}

export const LibrosDP = [
    { name: "Compras", value: "1" },
    { name: "Ventas a Contribuyente", value: "2" },
    { name: "Ventas Consumidor Final", value: "3" }
];

export const meses = [
    {name: "Enero", value: 1},
    {name: "Febrero", value: 2},
    {name: "Marzo", value: 3},
    {name: "Abril", value: 4},
    {name: "Mayo", value: 5},
    {name: "Junio", value: 6},
    {name: "Julio", value: 7},
    {name: "Agosto", value: 8},
    {name: "Septiembre", value: 9},
    {name: "Octubre", value: 10},
    {name: "Noviembre", value: 11},
    {name: "Diciembre", value: 12}
];

export const id_columns_editor = {
    "Correlativo": 1,
    "Numero_de_Registro": 3,
    "Fecha": 11,
    "Numero_de_Comprobante": 12,
    "Nombre_del_Proveedor": 13,
    "ExentasInternas": 25,
    "ExentasImportacion": 26,
    "Importaciones": 15,
    "Internas": 14,
    "Retencion": 17,
    "IVA": 16,
    "Compras_totales": 18
}

export const columns_libros = {
    "correlativo": 1,
    "correlativo_por_documento": 2,
    "no_registro": 3,
    "cliente": 4,
    "propias_excentas": 5,
    "propias_no_sujetas": 6,
    "propias_gravadas": 7,
    "propias_debito_fiscal": 8,
    "iva_retenido": 9,
    "total_ventas": 10,
    "fecha": 11,
    "no_comprobante": 12,
    "proveedor": 13,
    "gravadas_internas": 14,
    "gravadas_importacion": 15,
    "iva": 16,
    "retencion": 17,
    "compras_totales": 18,
    "del": 19,
    "al": 20,
    "correlativo_maquina_registradora": 21,
    "gravadas_locales": 22,
    "gravadas_exportacion": 23,
    "ventas_a_cuentas_de_terceros": 24,
    "exentas_internas": 25,
    "exentas_importacion": 26
}

export const new_columns_libros = {
    Fecha: 11,
    Comprobante: 12,
    DelNo: 19,
    AlNo: 20,
    NoCorrelativo: 2,
    Registro: 3,
    Proveedor: 13,
    NombreCliente: 4,
    Gravadas: 14,
    VentasGravadas: 7,
    Locales: 22,
    Iva: 16,
    Debito: 8,
    Fovial: 25,
    Retencion: 17,
    Exentas: 25,
    Importacion: 15,
    NoSujetas: 25,
    Total: 18,
    TotalVenta: 10,
}