import React, { useRef, useState } from "react"
import { size } from "lodash"
import moment from "moment"
import { NumericFormat } from "react-number-format"
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row"
import { Button } from "primereact/button"
import { useLibros } from "../../hooks"
import {FormLibro} from "../../components/libro"
import { new_columns_libros } from "../../utils/dictionary"

export function TableLibroConsumidor({ Libro, updateLibroTable }) {

    const [visible, setVisible] = useState(false);
    const [contentDialog, setContentDialog] = useState(false);

    const onCampos = {
        Fecha: true,
        DelNo: true,
        AlNo: true,
        Locales: true,
        Exentas: true,
        Subtotal: true,
        NoSujetas: true,
        TotalVenta: true,
    }

    const { deleteLibroDetalle, updateLibroDetalle_add } = useLibros();

    const dt = useRef(null);
    const toast = useRef(null);

    const Ventas_no_SujetasTotal = () => {

        let total = 0

        if (size(Libro) > 0) {

            for (const value of Libro) {
                if (value.Ventas_no_Sujetas != null) {

                    total += parseFloat(value.Ventas_no_Sujetas)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const LocalesTotal = () => {

        let total = 0

        if (size(Libro) > 0) {

            for (const value of Libro) {
                if (value.Locales != null) {

                    total += parseFloat(value.Locales)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const ExportacionTotal = () => {

        let total = 0

        if (size(Libro) > 0) {

            for (const value of Libro) {
                if (value.Exportacion != null) {

                    total += parseFloat(value.Exportacion)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const RetencionTotal = () => {

        let total = 0

        if (size(Libro) > 0) {

            for (const value of Libro) {
                if (value.Retencion != null) {

                    total += parseFloat(value.Retencion)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const Venta_TotalTotal = () => {
        let total = 0

        if (size(Libro) > 0) {

            for (const value of Libro) {
                if (value.Venta_Total != null) {

                    total += parseFloat(value.Venta_Total)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const exportExcel = async () => {

        let config_format_ap = []

        for (const libro of Libro) {

            let ap_data = {
                Correlativo: libro.Correlativo,
                Fecha: libro.Fecha,
                Numero_de_Comprobante: libro.Numero_de_Comprobante,
                Numero_de_Registro: libro.Numero_de_Registro,
                Nombre_del_Proveedor: libro.Nombre_del_Proveedor,
                ExentasInternas: libro.ExentasInternas,
                ExentasImportacion: libro.ExentasImportacion,
                Internas: libro.Internas,
                Importaciones: libro.Importaciones,
                IVA: libro.IVA,
                Retencion: libro.Retencion,
                Compras_totales: libro.Compras_totales,
            }

            config_format_ap.push(ap_data)

        }

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(config_format_ap);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'LibroCompras');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const exportCSV = async (selectionOnly) => {
        let csvContent = "data:text/csv;charset=utf-8,";

        for await (const data of Libro) {

            let null_exentasInternas = (data.ExentasInternas == null) ? "0.00" : data.ExentasInternas;
            let valor_exentasInternas = (null_exentasInternas < 0) ? (null_exentasInternas * (-1)) : null_exentasInternas;
            let null_exentasImportacion = (data.ExentasImportacion == null) ? "0.00" : data.ExentasImportacion;
            let valor_exentasImportacion = (null_exentasImportacion < 0) ? (null_exentasImportacion * (-1)) : null_exentasImportacion;
            let null_importaciones = (data.Importaciones == null) ? "0.00" : data.Importaciones;
            let valor_importaciones = (null_importaciones < 0) ? (null_importaciones * (-1)) : null_importaciones;

            let null_Internas = (data.Internas == null) ? "0.00" : data.Internas;
            let valor_Internas = (null_Internas < 0) ? (null_Internas * (-1)) : null_Internas;
            let null_IVA = (data.IVA == null) ? "0.00" : data.IVA;
            let valor_IVA = (null_IVA < 0) ? (null_IVA * (-1)) : null_IVA;
            let null_Compras_totales = (data.Compras_totales == null) ? "0.00" : data.Compras_totales;
            let valor_Compras_totales = (null_Compras_totales < 0) ? (null_Compras_totales * (-1)) : null_Compras_totales;

            let tipo_documento = "03";

            if (null_exentasInternas < 0 || null_exentasImportacion < 0 || null_importaciones < 0 || null_Internas < 0 || null_IVA < 0 || null_Compras_totales < 0) {
                tipo_documento = "05";
            }

            if (data.Numero_de_Comprobante.includes("DTE")) {
                csvContent += `${moment(data.Fecha).format("DD/MM/YYYY")};4;${tipo_documento};${data.Numero_de_Comprobante.replaceAll("-", '')};${data.Numero_de_Registro};${data.Nombre_del_Proveedor.replace(/,/g, ',')};${valor_exentasInternas};${valor_exentasImportacion};${valor_importaciones};${valor_Internas};0.00;0.00;0.00;${valor_IVA};${valor_Compras_totales};;1;2;2;5;3\n`;
            } else {
                csvContent += `${moment(data.Fecha).format("DD/MM/YYYY")};1;${tipo_documento};${data.Numero_de_Comprobante.replaceAll("-", '')};${data.Numero_de_Registro};${data.Nombre_del_Proveedor.replace(/,/g, ',')};${valor_exentasInternas};${valor_exentasImportacion};${valor_importaciones};${valor_Internas};0.00;0.00;0.00;${valor_IVA};${valor_Compras_totales};;1;2;2;5;3\n`;
            }
        }

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `Compras.csv`);
        document.body.appendChild(link); // Required for Firefox
        link.click();

    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
        </div>
    );

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">Editar Detalle de Libro</span>
        </div>
    );

    const footerContent = (
        <div>
            
        </div>
    );

    const templateFecha = (rowData) => {
        const date_str = moment(rowData.Fecha).format("YYYY-MM-DD")
        return (date_str == "Invalid date") ? rowData.Fecha : date_str
    }

    const deleteDetalleLibro = async (rowValue, e) => {

        confirmPopup({
            target: e,
            message: '¿Deseas eliminar esta fila?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: async () => {
                toast.current.show({ severity: 'info', summary: 'Confirmado', detail: 'El registro se ha eliminado Correctamente', life: 3000 });
                await deleteLibroDetalle(rowValue.Libro_id, rowValue.FilaGroup)
                await updateLibroTable(rowValue.Libro_id);
            },
            reject: () => {
                toast.current.show({ severity: 'warn', summary: 'Rechazado', detail: 'No se Elimino el registro', life: 3000 });
            }
        });

    }

    const onUpdateLibroTable = async (id_libro) => {

        if (id_libro != undefined){

            await updateLibroTable(id_libro)

        }
    }

    const updateFormData = async (formValue, Libro, Fila) => {
        
        for await (const data of Object.keys(formValue)) {
            const value_form = formValue[data]

            if (value_form != "" && data in onCampos) {

                if (data in new_columns_libros) {

                    let data_save = {
                        /* FilaGroup: Fila,
                        Libro: Libro,
                        Columna: new_columns_libros[data], */
                        Valor: value_form
                    }

                    await updateLibroDetalle_add(data_save, Libro, Fila, new_columns_libros[data])

                }

            }

        }

        onUpdateLibroTable(Libro)

        setVisible(false)
    }

    const updateDetalleLibro = (rowData) => {

        const onCamposValue = {
            Fecha: rowData.Fecha,
            DelNo: rowData.Del_Numero,
            AlNo: rowData.Al_Numero,
            Locales: rowData.Locales,
            Exentas: "",
            Subtotal: rowData.Locales,
            NoSujetas: (rowData.Ventas_no_Sujetas != null) ? rowData.Ventas_no_Sujetas : "0",
            TotalVenta: rowData.Venta_Total,
        }

        setContentDialog(<FormLibro campos={onCampos} responseUpdate={updateFormData} updateLibro={onUpdateLibroTable} tipoLibro={3} dataValue={onCamposValue} EditLibroId={rowData.Libro_id} EditLibroFila={rowData.FilaGroup} />)
        setVisible(true)
    }

    const actionTemplate = (rowdata) => {

        return (
            <div className="flex flex-wrap justify-content-center gap-3 mb-4">
                <Button severity="danger" onClick={(e) => deleteDetalleLibro(rowdata, e.target)} rounded icon="pi pi-times"></Button>
                <Button severity="warning" onClick={(e) => updateDetalleLibro(rowdata)} rounded icon="pi pi-pencil"></Button>
            </div>
        )
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
            </Row>
            <Row>
                <Column header="Fecha" field="Fecha" sortable rowSpan={2} ></Column>
                <Column header="Del Numero" field="Del_Numero" sortable rowSpan={2} ></Column>
                <Column header="Al Numero" field="Al_Numero" rowSpan={2} ></Column>
                <Column header="Ventas no Sujetas" field="Ventas_no_Sujetas" rowSpan={2} ></Column>
                <Column header="Locales" field="Locales" rowSpan={2} ></Column>
                <Column header="Exportacion" field="Exportacion"></Column>
                <Column header="Retencion" field="Retencion"></Column>
                <Column header="Ventas Totales" field="Venta_Total"></Column>
                <Column rowSpan={2} colSpan={2} ></Column>
            </Row>
        </ColumnGroup>
    )

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
                <Column footer={Ventas_no_SujetasTotal} />
                <Column footer={LocalesTotal} />
                <Column footer={ExportacionTotal} />
                <Column footer={RetencionTotal} />
                <Column footer={Venta_TotalTotal} />
                <Column />
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            <Toast ref={toast} />
            <ConfirmPopup />
            <Dialog visible={visible} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => {if (!visible) return; setVisible(false); }}>
                {contentDialog}
            </Dialog>
            <DataTable value={Libro}
                ref={dt}
                headerColumnGroup={headerGroup}
                footerColumnGroup={footerGroup}
                stripedRows
                size="small"
                sortField="Correlativo"
                sortOrder={-1}
                header={header}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                editMode="row" dataKey="id">
                <Column field="Fecha" sortable body={templateFecha}></Column>
                <Column field="Del_Numero"></Column>
                <Column field="Al_Numero"></Column>
                <Column field="Ventas_no_Sujetas"></Column>
                <Column field="Locales"></Column>
                <Column field="Exportacion"></Column>
                <Column field="Retencion"></Column>
                <Column field="Venta_Total"></Column>
                <Column body={actionTemplate}></Column>
            </DataTable>
        </>
    )
}