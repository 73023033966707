import React from "react";
import { useLibros } from "../../hooks"
import { FormLibro, TableLibroConsumidor } from "../../components/libro"
import { new_columns_libros } from "../../utils/dictionary"

export function ConsumidorFinalCliente () {

    const { libroDetalles, addLibroDetalle, getLibroDetalleFormatSize, getLibroDetalleFormat, setLibrosDetalles, loadingLibro } = useLibros();

    const onCampos = {
        Fecha: true,
        DelNo: true,
        AlNo: true,
        Locales: true,
        Exentas: true,
        Subtotal: true,
        NoSujetas: true,
        TotalVenta: true,
    }

    const onSaveCompras = async (formValue) => {

        const response_size_fila = await getLibroDetalleFormatSize(formValue.IdLibro);

        let data_save = {
            FilaGroup: response_size_fila + 1,
            Libro: formValue.IdLibro,
            Columna: 1,
            Valor: response_size_fila + 1
        }

        await addLibroDetalle(data_save)

        for await (const data of Object.keys(formValue)) {
            const value_form = formValue[data]

            if (value_form != "" && data in onCampos) {

                if (data in new_columns_libros) {

                    let data_save = {
                        FilaGroup: response_size_fila + 1,
                        Libro: formValue.IdLibro,
                        Columna: new_columns_libros[data],
                        Valor: value_form
                    }

                    await addLibroDetalle(data_save)

                }

            }

        }

        await getLibroDetalleFormat(formValue.IdLibro)
    }

    const onUpdateLibro = async (id_libro) => {

        if (id_libro != undefined){

            await getLibroDetalleFormat(id_libro)
        }else{
            setLibrosDetalles([]);
        }
    }

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Libro de Compras</h5>
                        <p>Libro de compras para cliente</p>

                        <FormLibro campos={onCampos} response={onSaveCompras} updateLibro={onUpdateLibro} tipoLibro={3} />

                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Tabla libro de compras</h5>
                        <p>Libro de compras para cliente</p>

                        {loadingLibro ? (
                            <div className="grid">
                                <div className="col-12 text-center">
                                    <i className="pi pi-spinner pi-spin" style={{fontSize: '5rem'}}></i>
                                </div>
                            </div>
                        ) : (

                            <TableLibroConsumidor Libro={libroDetalles} updateLibroTable={onUpdateLibro} />

                        )}

                    </div>
                </div>
            </div>
        </>
    )
}