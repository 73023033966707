import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"
import moment from "moment";
import {size} from "lodash"
import { Dropdown } from "primereact/dropdown"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { useLibros } from "../../hooks"
import { meses, LibrosDP } from "../../utils/dictionary"

export function DocumentosLegales() {

    const [dataLibro, setDataLibro] = useState([]);

    const { getClientesForDropdown, clientesDrop, getLibrosLegales, loadingLibro } = useLibros();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            const currentYear = moment().year();
            const startDate = moment().year(currentYear).month(formValue.Mes - 1).startOf('month');
            const endDate = moment().year(currentYear).month(formValue.Mes - 1).endOf('month');

            const response_libro_legal = await getLibrosLegales(formValue.Cliente, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), formValue.Libro);

            setDataLibro(response_libro_legal)
        }
    })

    useEffect(() => {
        (async () => {
            await getClientesForDropdown()
        })()
    }, [])

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Reporte Legales</h5>
                        <p>Seleccione todos los campos para poder generar los libros</p>

                        <form onSubmit={formik.handleSubmit}>

                            <div className="grid my-3">
                                <div className="col-4">
                                    <span className="p-float-label">
                                        <Dropdown id="Cliente" name="Cliente" className="w-full" filter options={clientesDrop} optionLabel="name" value={formik.values.Cliente} onChange={(e) => formik.setFieldValue("Cliente", e.value)} />
                                        <label htmlFor="">Cliente</label>
                                    </span>
                                </div>
                                <div className="col-4">
                                    <span className="p-float-label">
                                        <Dropdown filter id="Libro" name="Libro" className="w-full" value={formik.values.Libro} onChange={(e) => formik.setFieldValue("Libro", e.value)} options={LibrosDP} optionLabel="name" />
                                        <label htmlFor="Libro">Libro</label>
                                    </span>
                                </div>
                                <div className="col-4">
                                    <span className="p-float-label">
                                        <Dropdown filter id="Mes" name="Mes" className="w-full" value={formik.values.Mes} onChange={(e) => formik.setFieldValue("Mes", e.value)} options={meses} optionLabel="name" />
                                        <label htmlFor="Mes">Mes</label>
                                    </span>
                                </div>
                            </div>

                            <div className="grid">
                                <div className="col-12 text-right">
                                    <Button type="Submit" severity="primary">Generar Libro</Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            {loadingLibro ? (
                
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spin pi-spinner"></i>
                    </div>
                </div>
                
            ) : (
                <div className="grid">
                    <div className="col-12">

                        {size(dataLibro) > 0 ? (
                            <div className="grid">
                                <div className="col-12 text-center">
                                    <h2>No se ha seleccionado libro ó el cliente no tiene este libro creado</h2>
                                </div>
                            </div>
                        ) : (
                            <div className="card">
                                
                            </div>
                        )}

                    </div>
                </div>
            )}
        </>
    )
}

function initialValues() {
    return {
        Libro: "",
        Mes: "",
        Cliente: "",
    }
}

function validationSchema() {
    return {
        Libro: Yup.string(),
        Mes: Yup.string(),
        Cliente: Yup.string(),
    }
}