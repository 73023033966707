import React, { useState } from "react"
import moment from "moment"
import "moment/locale/es"
import {getProveedorApi, addProveedorApi} from "../api/client/proveedor"
import {getClienteApi} from "../api/client/cliente"
import {getLibroApi, getLibroFormatApi} from "../api/ledger/libro"
import {addLibroDetalleApi, getLibroDetalleApi, updateLibroDetalleApi, deleteLibroDetalleApi} from "../api/ledger/librodetalle"
import {useAuth} from "./useAuth"
import {Libros} from "../utils/dictionary"
import { size } from "lodash"

export function useLibros () {

    const [proveedores, setProveedores] = useState(null)
    const [libroDetalles, setLibrosDetalles] = useState(null)
    const [librosDrop, setLibrosDrop] = useState(null)
    const [clientesDrop, setClienteDrop] = useState(null)
    const [loadingLibro, setLoadingLibro] = useState(false)
    const [libroId, setLibroId] = useState(0);
    const [error, setError] = useState(false)

    const {auth} = useAuth()

    const getProveedorByNrc = async (Nrc) => {
        try {
            setLoadingLibro(true)
            const response = await getProveedorApi(auth.token, Nrc)
            setLoadingLibro(false)
            return response
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getClientesForDropdown = async () => {
        try {
            setLoadingLibro(true)
            const response = await getClienteApi(auth.token);
            let response_format = []
            for await (const cliente of response){
                response_format.push({
                    name: cliente.NombreCliente,
                    value: cliente.id
                })
            }

            setClienteDrop(response_format)
            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getLibrosForDropDown = async (Cliente, TipoLibro) => {
        try {
            setLoadingLibro(true)
            const response = await getLibroApi(auth.token, Cliente, TipoLibro)
            let response_format = []
            for await (const libro of response){
                response_format.push({
                    name: `${Libros[libro.TipoLibro]} - ${moment(libro.Fecha).format("MMMM")} - ${moment(libro.Fecha).format("YYYY")}`,
                    value: libro.id
                })
            }

            setLibrosDrop(response_format)
            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getLibrosLegales = async (Cliente, FechaInicio, FechaFinal, TipoLibro) => {
        try {
            setLoadingLibro(true)
            const response = await getLibroApi(auth.token, Cliente, TipoLibro, FechaInicio, FechaFinal)
            setLoadingLibro(false)

            return response;
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getLibrosAnalisis = async (Cliente, FechaInicio, FechaFinal) => {
        try {
            setLoadingLibro(true)
            const response = await getLibroApi(auth.token, Cliente, "", FechaInicio, FechaFinal)
            setLoadingLibro(false)

            return response;
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getDatosLibro = async (id) => {
        try {
            setLoadingLibro(true)
            const response = await getLibroApi(auth.token,"", "", "", "", id)
            setLoadingLibro(false)

            return response;
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getLibroDetalleFormat = async (id_libro) => {
        try {
            setLoadingLibro(true)
            const response = await getLibroFormatApi(auth.token, id_libro)

            setLibrosDetalles(response)
            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getLibroDetalleFormatSize = async (id_libro) => {
        try {
            setLoadingLibro(true)
            const response = await getLibroFormatApi(auth.token, id_libro)

            setLoadingLibro(false)

            return size(response)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getLibroDetalleFormatFilter = async (Cliente, Fecha, TipoLibro) => {
        try {
            setLoadingLibro(true)
            const response = await getLibroApi(auth.token, Cliente, TipoLibro, Fecha, Fecha)
            console.log(response);
            
            const response_format = await getLibroFormatApi(auth.token, response[0].id)
            console.log(response[0].id);


            setLibrosDetalles(response_format)
            setLoadingLibro(false)

            return response[0].id;
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getLibroDetalleFormatR = async (id_libro) => {
        try {
            setLoadingLibro(true)
            const response = await getLibroFormatApi(auth.token, id_libro)

            setLoadingLibro(false)

            return response
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const addLibroDetalle = async (data) => {
        try {
            setLoadingLibro(true)
            await addLibroDetalleApi(data, auth.token);
            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const addProveedor = async (data) => {
        try {
            setLoadingLibro(true)
            await addProveedorApi(data, auth.token)
            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const updateLibroDetalle = async (data, Libro, Fila, Propiedad) => {
        try {
            setLoadingLibro(true)
            const get_id_libro = await getLibroDetalleApi(auth.token, Libro, Propiedad, Fila)

            await updateLibroDetalleApi(get_id_libro[0].id, data, auth.token)
            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const updateLibroDetalle_add = async (data, Libro, Fila, Propiedad) => {
        try {
            setLoadingLibro(true)
            const get_id_libro = await getLibroDetalleApi(auth.token, Libro, Propiedad, Fila)
            if (size(get_id_libro) > 0){

                await updateLibroDetalleApi(get_id_libro[0].id, data, auth.token)
            }else{
                await addLibroDetalleApi({
                    Valor: data.Valor,
                    Columna: Propiedad,
                    Libro: Libro,
                    FilaGroup: Fila
                }, auth.token);
            }

            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getResolucionCliente = async (idCliente) => {
        try {
            setLoadingLibro(true)
            const response_cliente = await getClienteApi(auth.token, idCliente);
            setLoadingLibro(false)

            return response_cliente;
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const getLibroDetalleDelete = async (Libro, Fila) => {
        try {
            setLoadingLibro(true)
            const get_id_libro = await getLibroDetalleApi(auth.token, Libro, "", Fila)
            setLoadingLibro(false)

            return get_id_libro
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const deleteLibroDetalleDelete = async (id) => {
        try {
            setLoadingLibro(true)
            await deleteLibroDetalleApi(id, auth.token)
            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    const deleteLibroDetalle = async (libro_id, fila_group) => {
        try {
            setLoadingLibro(true)
            const response_delete_detalle = await getLibroDetalleApi(auth.token, libro_id, "", fila_group);

            for await (const detalle of response_delete_detalle){
                await deleteLibroDetalleDelete(detalle.id)
            }

            setLoadingLibro(false)
        } catch (error) {
            setLoadingLibro(false)
            setError(error)
        }
    }

    return {
        proveedores,
        loadingLibro,
        librosDrop,
        clientesDrop,
        setLibrosDetalles,
        libroDetalles,
        libroId,
        error,
        getProveedorByNrc,
        getClientesForDropdown,
        getLibrosForDropDown,
        getLibroDetalleFormat,
        addLibroDetalle,
        updateLibroDetalle,
        getLibrosAnalisis,
        getLibroDetalleFormatR,
        getDatosLibro,
        addProveedor,
        getResolucionCliente,
        getLibroDetalleDelete,
        deleteLibroDetalleDelete,
        updateLibroDetalle_add,
        getLibrosLegales,
        getLibroDetalleFormatFilter,
        getLibroDetalleFormatSize,
        deleteLibroDetalle
    }
}